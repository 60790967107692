$('#logoutLink').click(() => {
    $.ajax({
        url      : '/auth/logout/',
        type     : 'post',
        data     : {},
        dataType : 'json',
        success  : function (response) {
            if (response["status"]) {
                let windowTitle = 'Logout Successful.';
                let alertClass = 'alert-success';
                let alertMessage = "<strong>You are now logged out.</strong><br>Please click the 'Close' button to return to the home page.";
                let bodyMessage = '';
                let footerMessage = '<button type="button" class="btn btn-sm btn-ncats gap-right" data-dismiss="modal">Close</button>';
                showModal(windowTitle, alertClass, alertMessage, bodyMessage, footerMessage);

                $('#messageModal').on('hidden.bs.modal', () => {
                    window.location.href = '/';
                });
            }
        }
    });
});

$('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
    if (!$(this).next().hasClass('show')) {
        $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
    }
    let $subMenu = $(this).next(".dropdown-menu");
    $subMenu.toggleClass('show');


    $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
        $('.dropdown-submenu .show').removeClass("show");
    });


    return false;
});

$('#goTop').on('click', function (e) {
    $("html, body").animate({scrollTop : $("body.goTop").offset().top}, 500);
});

$(function () {
    // Make nav-item for current view active
    $('ul.navbar-nav').find("li.active").removeClass("active");
    $('a[href="' + location.pathname + '"]').closest('li.nav-item').addClass('active');
});

$.ajaxSetup({
    beforeSend : function (xhr, settings) {
        if (!(/^http:.*/.test(settings.url) || /^https:.*/.test(settings.url))) {
            // Only send the token to relative URLs i.e. locally.
            xhr.setRequestHeader("X-CSRFToken", Cookies.get('csrftoken'));
        }
    }
});

/**
 * Calls the global bootstrap modal and displays parameter info on screen
 * @method showModal
 * @param  {String}    windowTitle   HTML markup to fill title space in the top next to the logo]
 * @param  {String}    alertClass    Bootstrap class [alert-danger, alert-success, alert-warning, alert-info]
 * @param  {String}    alertMessage  HTML markup to fill alert box
 * @param  {String}    bodyMessage   HTML markup to fill modal body
 * @param  {String}    footerMessage HTML markup to fill modal footer
 * @param  {String}    modalClass    String value to be used as modal class for sizing
 */
function showModal(windowTitle, alertClass, alertMessage, bodyMessage, footerMessage, modalClass = '') {
    let messageModal = $('#messageModal');
    let alertSelector = $('#alertMessage');

    if (modalClass) {
        messageModal.find('div.modal-dialog').addClass(modalClass);
    } else {
        messageModal.find('div.modal-dialog').attr('class', 'modal-dialog');
    }

    $('#windowTitle').html(windowTitle);
    alertSelector.removeClass();

    if (alertClass) {
        alertSelector.addClass("alert " + alertClass);
    }

    alertSelector.html(alertMessage);
    $('#bodyMessage').html(bodyMessage);
    $('#footerMessage').html(footerMessage);
    messageModal.modal('show');
}

/**
 * Redirects the browser to the home page by default,
 * or to a uri if the url parameter is passed.
 * @method redirect
 * @param  {String} url URI resource (e.g. '/?route=maintenance')
 */
function redirect(url) {
    let str = (url == null) ? '' : url;
    window.location = "https://" + window.location.hostname + str;
}

/**
 * Takes a lowercase string as input and returns a Proper Case string. [ncats => Ncats]
 * @method properCase
 * @param  {[type]}   string [description]
 * @return {string}          [description]
 */
function properCase(string) {
    if (string) {
        string = string.toLowerCase();
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return '';
}

function capitalizeEach(string) {
    if (string) {
        if (typeof string !== 'string') {
            return '';
        }

        string = string.toLowerCase();
        return string.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }

    return '';
}

function trimFromLast(char, str, caseInsensitive = false) {
    let index;

    if (caseInsensitive) {
        index = str.toLowerCase().lastIndexOf(char);
    } else {
        index = str.lastIndexOf(char);
    }

    return str.substr(0, index);
}

function trimFromFirst(char, str, caseInsensitive = false) {
    let index;

    if (caseInsensitive) {
        index = str.toLowerCase().indexOf(char) + char.length;
    } else {
        index = str.indexOf(char) + char.length;
    }

    return str.substr(index);
}

(function () {
    window.whenDefined = function ($context, $variable, $callback) {
        if ($context[$variable]) {
            $callback();
        } else {
            Object.defineProperty($context, $variable, {
                configurable : true,
                enumerable   : true,
                writeable    : true,
                get          : function () {
                    return this['_' + $variable];
                },
                set          : function (val) {
                    this['_' + $variable] = val;
                    $callback();
                }
            });
        }
    };

}).call(this);

function htmlDecode(input) {
    let doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
}

function addSearchIcons(selector) {
    let searchBox = $(selector).find('div.bs-searchbox');
    searchBox.prepend('<i class="fas fa-search"></i>');
    searchBox.find('input').css({
        'padding-left' : '30px',
        'height'       : '25px'
    });

    let icon = searchBox.find('.fas');
    icon.css({
        'position'       : 'absolute',
        'margin-top'     : '5px',
        'margin-left'    : '5px',
        'pointer-events' : 'none',
    });
}

function getUrlVars()
{
    let vars = [], hash;
    let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(let i = 0; i < hashes.length; i++)
    {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
}

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        let cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = jQuery.trim(cookies[i]);
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}

function initializePopovers() {
    $('[data-toggle="popover"]').popover();
    $('.popover-dismiss').popover({
        trigger : 'focus'
    });
}

function initializeTooltips() {
    $('body').tooltip({selector : '[data-toggle=tooltip]'});
    $('[data-toggle="tooltip"]').tooltip();
}

function getDate(delimiter = true, delimiterChar = '/') {
    let today = new Date();
    let dd = today.getDate();

    let mm = today.getMonth()+1;
    let yyyy = today.getFullYear();

    if(dd<10)
    {
        dd=`0${dd}`;
    }

    if(mm<10)
    {
        mm=`0${mm}`;
    }

    today = `${mm}${dd}${yyyy}`;

    if (delimiter) {
       today = `${mm}${delimiterChar}${dd}${delimiterChar}${yyyy}`;
    }

    return today;
}

function hasWhiteSpace(string) {
  return string.indexOf(' ') >= 0;
}

function capitalizeFirstLetter(string)
{
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
}

function pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

function setupBreadcrumb(path) {
    let breadcrumbList = $('ol.breadcrumb');
    let breadcrumbContent = `<li class="breadcrumb-item"><a href="/">Home</a></li>`;
    if (Array.isArray(path)) {
        path.forEach((item, index) => {
            if (index === (path.length - 1)) {
                breadcrumbContent += `<li class="breadcrumb-item active"><span style="font-weight: normal">${item}</span></li>`;
            } else {
                breadcrumbContent += `<li class="breadcrumb-item active">${item}</li>`;
            }
        });
    }

    breadcrumbList.html("");
    breadcrumbList.append(breadcrumbContent);
}

function splitStringToList(data, delimiter, strip = false, stripChar = null, ordered = false, formatted = false) {
    let wrappingTag = ordered ? 'ol' : 'ul';
    let strippedString = strip ? data.replaceAll(stripChar, " ") : data;

    if (strippedString.includes(delimiter)) {
        let listContent = "";
        let stringArray = strippedString.split(delimiter);

        stringArray.forEach(string => {
            string = formatted ? capitalizeEach(string) : string;

            listContent += `<li>${string}</li>`
        });

        return `<${wrappingTag}>${listContent}</${wrappingTag}>`;

    } else {
        return formatted ? capitalizeEach(strippedString) : strippedString;
    }
}

let analyticsKeys = {
    'api' : {
        'raresource-dev.ncifcrf.gov': "2THI9jJQTw-EEpws_UlHkQ",
        'raresource-test.ncifcrf.gov': "",
        'raresource.nih.gov': "Ruzeke3zSbybpqNu9-Wv2A",
    },
    'id' : {
        'raresource-dev.ncifcrf.gov': "G-ZF1T7THMNL",
        'raresource-test.ncifcrf.gov': "",
        'raresource.nih.gov': "G-7XXSEYHVYC",
    }
};

function trackAjaxEvent(type, value) {
    const hostName = window.location.hostname;
    const measurementId = analyticsKeys.id[hostName];
    const apiSecret = analyticsKeys.api[hostName];
    const client_id = gaGlobal.vid;

    fetch(`https://www.google-analytics.com/mp/collect?measurement_id=${measurementId}&api_secret=${apiSecret}`, {
        method : "POST",
        body   : JSON.stringify({
            "client_id" : client_id,
            "events": [{
                "name": "select_content",
                "params": {
                    "content_type": type,
                    "content_id": value
                }
            }]
        })
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        // Process the response data
        // console.log(response);
    })
    .catch(error => {
        // Handle errors that occurred during the fetch request
        console.error('There was a problem with the fetch operation:', error);
    });
}